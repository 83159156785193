define("discourse/plugins/discourse-lexicon-plugin/discourse/components/base-deeplink-template", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h1>{{i18n "deeplink.title"}}</h1>
  <button
    type="button"
    id="open-in-lexicon-app"
    class="btn btn-primary"
    {{action (route-action "open" @model.link)}}
  >
    {{#if @model.redirect_to_app}}
      {{i18n "deeplink.button_label_open_app"}}
    {{else}}
      {{#if @model.is_pm}}
        {{i18n "deeplink.button_label_view_message"}}
      {{else}}
        {{i18n "deeplink.button_label_view_post"}}
      {{/if}}
    {{/if}}
  </button>
  */
  {
    "id": "FTmEZ8Wu",
    "block": "[[[10,\"h1\"],[12],[1,[28,[35,1],[\"deeplink.title\"],null]],[13],[1,\"\\n\"],[11,\"button\"],[24,1,\"open-in-lexicon-app\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,3],[[30,0],[28,[37,4],[\"open\",[30,1,[\"link\"]]],null]],null],[12],[1,\"\\n\"],[41,[30,1,[\"redirect_to_app\"]],[[[1,\"    \"],[1,[28,[35,1],[\"deeplink.button_label_open_app\"],null]],[1,\"\\n\"]],[]],[[[41,[30,1,[\"is_pm\"]],[[[1,\"      \"],[1,[28,[35,1],[\"deeplink.button_label_view_message\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,1],[\"deeplink.button_label_view_post\"],null]],[1,\"\\n\"]],[]]]],[]]],[13]],[\"@model\"],false,[\"h1\",\"i18n\",\"button\",\"action\",\"route-action\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-lexicon-plugin/discourse/components/base-deeplink-template.hbs",
    "isStrictMode": false
  });
  class BaseDeeplinkTemplateComponent extends _component2.default {}
  _exports.default = BaseDeeplinkTemplateComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BaseDeeplinkTemplateComponent);
});